<template>
  <div class="mobile-page">
    <el-form label-width="40px" :inline="true" class="form">
      <el-form-item label="名称" class="form-item">
        <el-input
          v-model="name"
          placeholder="请输入"
          style="flex: 1; min-width: 150px; max-width: 50%"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item">
        <el-button type="primary" round @click="handleClick">查询</el-button>
      </el-form-item>
    </el-form>

    <div class="table-container">
      <el-table :data="tableData">
        <el-table-column prop="customerName" label="名称"></el-table-column>
        <el-table-column prop="taxNum" label="税号"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      name: '',
      tableData: []
    }
  },
  methods: {
    handleClick() {
      if (this.name === '') {
        this.tableData = []
        this.$message.warning('请输入名称')
        return
      }
      axios
        .get('http://invoice.fisheryvms.com/invoice/getByName', {
          params: { name: this.name }
        })
        .then(({ data: response }) => {
          if (response.code === 200) {
            this.tableData = response.data || [] // 更新表格数据
          } else {
            this.$message.warning(response.message)
          }
        })
    }
  }
}
</script>

<style scoped>
/* 基础样式 */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.mobile-page {
  display: flex;
  flex-direction: column; /* 垂直排列子元素 */
  justify-content: flex-start; /* 垂直对齐方式（顶部对齐） */
  align-items: center; /* 水平居中 */
  width: 100%; /* 使容器宽度占满整个屏幕 */
  padding: 20px; /* 为容器添加内边距 */
  height: 100vh; /* 使容器的高度占满整个屏幕 */
  box-sizing: border-box; /* 确保内边距不超出容器 */
}

.form {
  display: flex; /* 使用 flex 布局 */
  flex-direction: row; /* 横向排列 */
  justify-content: flex-start; /* 元素从左到右排列 */
  width: 100%; /* 宽度占满父容器 */
  margin-bottom: 20px;
}

.form-item {
  display: flex;
  align-items: center; /* 水平居中 */
  justify-content: center;
  margin-right: 10px;
}

.table-container {
  width: 100%;
  max-height: 60vh; /* 表格最大高度为 60% 屏幕高度 */
  overflow-y: auto; /* 如果表格内容过多，允许滚动 */
}

header {
  background-color: #007bff;
  color: #fff;
  padding: 15px;
  text-align: center;
}
</style>
